* {
  margin: 0 ;
  padding: 0 ; 
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  display: flex;
  width: 100%;
}


#modal-container {
  position: absolute;
  left: 68%;
  z-index: 4;
  width: 32%;
}

#modal-center-container {
  position: absolute;
  z-index: 5;
  width: 75%;
  height: 95%;

  display: flex;
  justify-content: center;
  align-items: center;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
