.cv-container {
  max-width: 1000px;
  margin: 2rem auto;
  padding: 0 1rem;
  font-family: 'Roboto', 'Arial', sans-serif;
}

.cv-actions {
  margin-bottom: 1.5rem;
  text-align: right;
}

.cv-download-btn {
  background-color: #0056b3;
  color: white;
  padding: 0.6rem 1.2rem;
  border-radius: 4px;
  border: none;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s;
}

.cv-download-btn:hover {
  background-color: #003d82;
}

.cv-document {
  background-color: white;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.15);
  border-radius: 6px;
  overflow: hidden;
  max-width: 900px;
  margin: 0 auto;
  position: relative;
  padding-left: 2rem;
}

.cv-content {
  padding: 3rem;
  color: #333;
}

.cv-document-content {
  line-height: 1.5;
}

.cv-header {
  margin-top: 1.5rem;
  margin-bottom: 1rem;
  color: #0056b3;
  font-weight: 600;
}

.cv-header-1 {
  font-size: 2.2rem;
  text-align: center;
  margin-bottom: 1.5rem;
  color: #003366;
  border-bottom: 2px solid #eee;
  padding-bottom: 0.75rem;
}

.cv-header-2 {
  font-size: 1.5rem;
  border-bottom: 1px solid #eee;
  padding-bottom: 0.5rem;
  margin-top: 2rem;
}

.cv-header-3 {
  font-size: 1.25rem;
}

.cv-paragraph {
  margin-bottom: 1rem;
  line-height: 1.6;
}

.cv-paragraph a {
  color: #0056b3;
  text-decoration: none;
}

.cv-paragraph a:hover {
  text-decoration: underline;
}

.cv-list {
  margin-bottom: 1.5rem;
  padding-left: 1.5rem;
  list-style-type: none;
  text-align: left;
}

.cv-list-item-container {
  display: flex;
  gap: 5rem;
  margin-bottom: 0.5rem;
  line-height: 1.6;
}

.cv-list-item-label {
  font-weight: 600;
  min-width: 180px;
  color: #333;
  text-align: left;
}

.cv-list-item-value {
  text-align: left;
  flex: 1;
  color: #555;
}


.cv-footer {
  text-align: center;
  padding: 1rem;
  background-color: #f8f9fa;
  color: #6c757d;
  font-size: 0.875rem;
  border-top: 1px solid #eee;
}

.cv-loading, .cv-error {
  text-align: center;
  padding: 2rem;
  font-size: 1.2rem;
  color: #6c757d;
}

.cv-error {
  color: #dc3545;
}

@media print {
  .cv-container {
    margin: 0;
    padding: 0;
    max-width: none;
  }
  
  .cv-actions {
    display: none;
  }
  
  .cv-document {
    box-shadow: none;
    border-radius: 0;
    max-width: none;
  }
  
  .cv-content {
    padding: 0.5cm;
  }
  
  .cv-footer {
    position: fixed;
    bottom: 0;
    width: 100%;
  }
} 