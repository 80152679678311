.container {
    display: flex;
    width: 100%;
    height: 100%;
    background-image: url('../../assets/icons/svg/modalgrid.svg');
    background-size: contain; /* Adjust to your preference (cover, contain, etc.) */
    /* background-repeat: no-repeat;  */
    background-position: center center;  
    justify-content: space-between;
    padding: 20px 20px 0px 20px;
    flex-direction: column;
    font-family: var(--main-font);
}

.container_view{
    display: flex;
    width: 100%;
    height: 100%;
    /* background-image: url('../../assets/icons/svg/modalgrid.svg'); */
    background-size: contain; /* Adjust to your preference (cover, contain, etc.) */
    /* background-repeat: no-repeat;  */
    background-position: center center;  
    justify-content: space-between;
    padding: 20px 20px 0px 20px;
    flex-direction: column;
    font-family: var(--main-font);
}

.title_container {
    display: flex;
    align-items: baseline;
    justify-content: center;
    font-size: 20px;
    font-weight: 700;
    height: 10%;
}

.line_2 {
    background-color: var(--light-color);
    padding: 0px 20px;
    border-radius: var(--border-radius);
    font-size: 16px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;

}

.line_2_wrapper {
    background-color: var(--secondary-color);
    padding: 0px 20px;
    border-radius: var(--border-radius);
    transform: rotate(6deg);
    border-left: 20px ;
}

.submit_container {
    display: flex;
    font-size: 14;
    font-weight: 700;
    background-color: var(--light-color);
    align-items: center;
    justify-content: center;
    gap: 175px;
    width: 100%;
    height: 5%;
    padding: 40px 35px 15px 35px;
}

.form_container {
    height: 85%;
    padding: 25px;
    overflow-y: scroll;
}

.form_container::-webkit-scrollbar {
	height: 6px;
    width: 6px;
}


.form_container::-webkit-scrollbar-thumb{
	background-color: #aaa; 
	border-radius: 5px;
}

.button_1 {
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--light-color);
    background-color: var(--dark-color);
    padding: 12px;
    border-radius: 50px;
    cursor: pointer;
}

.button_2 {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px;
    border-radius: 50px;
    cursor: pointer;
}

.button_3 {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    border: none;
}

.button_2:hover {
    background-color: var(--primary-color);
}

.button_1:hover {
    background-color: var(--secondary-color);
    color: black;
}

.timeline_item {
  display: flex;
  margin-bottom: 24px;
  position: relative;
}

.timeline_item::before {
    content: '';
    position: absolute;
    left: 7.5px;
    top: 0px;
    bottom: -24px;
    width: 2px;
    background-color: #e0e0e0;
    z-index: 1;
}

.timeline_item:last-child::before {
  display: none;
}

.timeline_icon {
  width: 32px;
  margin-right: 12px;
  z-index: 2;
}

.icon_circle {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: #ceadf8;
}

.timeline_content {
  flex: 1;
}

.time {
  font-size: 14px;
  color: #666;
  margin-bottom: 4px;
}

.activity_text {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 4px;
}

.activity_meta {
  font-size: 14px;
  color: #666;
}

.activity_type {
  color: #666;
  font-style: italic;
}

.user_info {
  margin-left: 8px;
}

.textarea {
    height: 120px;
    background-color: var(--background-color);
    border: 1px solid #ECECEC;
    border-radius: 5px;
    font-size: 14px;
    font-weight: 600;
    font-family: var(--main-font);
    padding: 10px;
    width: 100%;
}

.textarea::-webkit-scrollbar {
	height: 6px;
    width: 6px;
}

.textarea::-webkit-scrollbar-thumb{
	background-color: #aaa; 
	border-radius: 5px;
}

.field_label {
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: 700;
    color: #535353;
}

.button_txt {
    width: 75px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;

}