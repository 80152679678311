.main_container {
  width: 100%;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  background-image: url('../../assets/icons/svg/modalgrid.svg');
  background-size: contain;
  font-family: var(--main-font);
}

.top_container {
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  
}

.title_container {
    display: flex;
    align-items: baseline;
    justify-content: center;
    font-size: 20px;
    font-weight: 700;
    height: 10%;
}

.line_2 {
    background-color: var(--light-color);
    padding: 0px 20px;
    border-radius: var(--border-radius);
    font-size: 16px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;

}

.line_2_wrapper {
    background-color: var(--secondary-color);
    padding: 0px 20px;
    border-radius: var(--border-radius);
    transform: rotate(6deg);
    border-left: 20px ;
}

.close_icon {
  font-size: 24px;
  cursor: pointer;
  color: #666;
  transition: color 0.2s;
}

.close_icon:hover {
  color: #000;
}

.mid_container {
  flex: 1;
  padding: 20px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.search_filter_container {
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid #eaeaea;
}

.search_box {
  width: 100%;
}

.searchbar {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ECECEC;
  font-size: 14px;
  font-weight: 600;
  font-family: var(--main-font);
  background-color: var(--background-color);
  height: 40px;
}

.searchbar:focus {
  border-color: transparent;
  outline: none;
}

.select_all_container {
  display: flex;
  align-items: center;
  gap: 8px;
}

.select_label {
  
  color: black;
  font-size: 14px;
  font-weight: 700;
  cursor: pointer;
}

.fields_list {
  display: flex;
  flex-direction: column;
  gap: 12px;
  max-height: 95%;
  overflow-y: auto;
}

.fields_list::-webkit-scrollbar {
  height: 6px;
  width: 6px;
}

.fields_list::-webkit-scrollbar-thumb {
  background-color: #aaa;
  border-radius: 5px;
}

.field_item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 8px 16px;
  border-bottom: 1px solid #f0f0f0;
  background-color: white;
  transition: background-color 0.2s ease;
}

.drag_handle {
  cursor: grab;
  padding: 0 12px;
  color: #999;
  font-size: 20px;
  display: flex;
  align-items: center;
}

.dragging {
  background-color: var(--background-color);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}

.field_checkbox {
  display: flex;
  align-items: center;
  gap: 8px;
  flex: 1;
}

.field_name {
    padding: 10px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  background-color: black;
  font-size: 14px;
  color: white;
  cursor: pointer;
  font-weight: 700;
  word-break: break-word;
  max-width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.field_input {
  flex: 1;
}

.field_input input {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ECECEC;
  font-size: 14px;
  font-weight: 600;
  font-family: var(--main-font);
  background-color: var(--background-color);
  height: 40px;
}

.field_input input:focus {
  border-color: transparent;
  outline: none;
}

.enabled_input {
  background-color: var(--background-color);
}

.disabled_input {
  background-color: #f0f0f0;
  color: #999;
  cursor: not-allowed;
}

.bottom_container {
  padding: 20px;
  display: flex;
 
  align-items: center;
  border-top: 1px solid #eaeaea;
}

.button_wrapper {
  display: flex;
  width: 100%;
  justify-content: space-around;
}

.primary_btn, .secondary_btn {
  padding: 10px 16px;
  border-radius: 50px;
  font-size: 14px;
  font-weight: 700;
  cursor: pointer;
  transition: all 0.2s ease;
}

.primary_btn {
  background-color: #000000;
  color: white;
  border: none;
}

.primary_btn:hover {
  background-color: var(--secondary-color);
  color: black;
}

.primary_btn:disabled {
  background-color: #9b9b9b;
  cursor: not-allowed;
}

.secondary_btn {
  background-color: #fff;
  color: #555;
  border: none;
}

.secondary_btn:hover {
    background-color: var(--primary-color);
}

.empty_message {
  text-align: center;
  padding: 20px;
  color: #999;
  font-style: italic;
} 
