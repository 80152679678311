/* Analytics Dashboard Styles */
.analyticsContainer {
  width: 100%;
  min-height: 100vh;
}

.controls {
  background: white;
  border-radius: 8px;
  padding: 24px;
  margin-bottom: 32px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
  border: 1px solid #e2e8f0;
  max-height: 50%;
  display: flex;
  align-items: center;
}

.viewSelectorWrapper{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  font-size: 14px;
}

.viewSelector {
  padding: 5px;
  margin-bottom: 24px;
  max-height: 90px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.viewSelector::-webkit-scrollbar {
  height: 4px;
  width: 4px;
}

.viewSelector::-webkit-scrollbar-thumb {
  background-color: #aaaaaa47; 
  border-radius: 5px;
}

.viewSelector h3 {
  font-size: 16px;
  color: #475569;
  margin-bottom: 14px;
  font-weight: 600;
}

.viewOptions {
  display: flex;
  flex-direction: column;
  gap: 16px;
  flex-wrap: wrap;
}

.viewOption {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  padding: 14px;
  border-radius: 6px;
  background-color: #f8fafc;
  cursor: pointer;
  transition: all 0.2s ease;
  flex: 1;
  min-width: 200px;
  border: 1px solid #edf2f7;
  margin-bottom: 10px;
}

.viewOption:hover {
  background-color: #f1f5f9;
  border-color: #e2e8f0;
}

.viewOption.selected {
  background-color: #e6f7ff;
  border-color: #91d5ff;
}

.viewOption h4 {
  font-size: 15px;
  color: #1e293b;
  margin-bottom: 4px;
  font-weight: 600;
}

.viewOption p {
  font-size: 13px;
  color: #64748b;
  margin: 0;
}

.filterControls {
  align-self: center;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  padding: 0px 24px;
}

.formGroup {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 0;
  min-width: 180px;
  flex: 1;
}

.formGroup label {
  font-size: 14px;
  font-weight: 700;
  color: #535353;
  margin-bottom: 8px;
}

.formGroup .required {
  color: #ef4444;
  margin-left: 4px;
}

.input,
.datePicker {
  height: 38px;
  border-radius: 6px;
  border: 1px solid #e2e8f0;
  padding: 0 12px;
  font-size: 14px;
  color: #1e293b;
  background-color: white;
  transition: all 0.2s;
  width: 100%;
  font-family: var(--primary-font);
}

.input:focus,
.datePicker:focus {
  outline: none;
  border-color: #2563eb;
  box-shadow: 0 0 0 2px rgba(37, 99, 235, 0.1);
}

.selectContainer {
  position: relative;
  width: 100%;
}

.loadingSelect {
  height: 38px;
  border-radius: 6px;
  border: 1px solid #e2e8f0;
  padding: 0 12px;
  font-size: 14px;
  color: #64748b;
  background-color: #f8fafc;
  display: flex;
  align-items: center;
  width: 100%;
}

.select {
  height: 38px;
  border-radius: 6px;
  border: 1px solid #e2e8f0;
  padding: 0 12px;
  font-size: 14px;
  color: #1e293b;
  background-color: white;
  transition: all 0.2s;
  width: 100%;
  appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 24 24' fill='none' stroke='%2364748b' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M6 9l6 6 6-6'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 12px center;
  background-size: 16px;
  cursor: pointer;
}

.select:focus {
  outline: none;
  border-color: #2563eb;
  box-shadow: 0 0 0 2px rgba(37, 99, 235, 0.1);
}

.select option {
  padding: 8px;
  font-size: 14px;
}

.fetchButton {
  height: 38px;
  background: var(--primary-color);
  border: none;
  border-radius: 19px;
  color: white;
  font-weight: 600;
  padding: 0 24px;
  cursor: pointer;
  transition: all 0.2s;
  font-size: 14px;
  white-space: nowrap;
  align-self: flex-end;
}

.fetchButton:hover {
  background: var(--secondary-color);
  transform: translateY(-1px);
}

.fetchButton:disabled {
  background: #94a3b8;
  cursor: not-allowed;
  transform: none;
}

.errorMessage {
  background-color: #fef2f2;
  border: 1px solid #fee2e2;
  border-radius: 6px;
  padding: 12px 16px;
  margin-bottom: 24px;
  color: #b91c1c;
  font-size: 14px;
}

.dashboardContent {
  margin-top: 32px;
}

.facetsGrid {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 16px;
}

.facetCard[data-type="line"],
.facetCard[data-type="bar"] {
  grid-column: span 6;
}

.facetCard[data-type="doughnut"] {
  grid-column: span 4;
}

@media (max-width: 1600px) {
  .facetCard[data-type="line"],
  .facetCard[data-type="bar"],
  .facetCard[data-type="doughnut"] {
    grid-column: span 6;
  }
}

@media (max-width: 1200px) {
  .facetCard[data-type="line"],
  .facetCard[data-type="bar"],
  .facetCard[data-type="doughnut"] {
    grid-column: span 12;
  }
}

.facetCard {
  background: white;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
  border: 1px solid #e2e8f0;
  transition: transform 0.2s, box-shadow 0.2s;
  height: 300px;
  display: flex;
  flex-direction: column;
}

.facetCard:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.08);
}

.facetHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 20px;
  border-bottom: 1px solid #f1f5f9;
}

.facetHeader h3 {
  font-size: 15px;
  color: #1e293b;
  margin: 0;
  font-weight: 600;
}

.statRow {
  display: flex;
  gap: 12px;
}

.facetStat {
  font-size: 14px;
  color: #1e293b;
  background-color: #f1f5f9;
  padding: 4px 10px;
  border-radius: 4px;
  font-weight: 500;
}

.statLabel {
  color: #64748b;
  font-weight: normal;
  margin-right: 2px;
  font-size: 13px;
}

.chartContainer {
  flex: 1;
  padding: 16px;
  position: relative;
  overflow: hidden;
}

.rechartsContainer {
  width: 100%;
  height: 100%;
  max-height: 250px;
}

.customTooltip {
  background-color: white;
  border-radius: 8px;
  padding: 12px 16px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  border: 1px solid #f1f5f9;
  transition: all 0.2s;
}

.tooltipLabel {
  font-size: 12px;
  color: #64748b;
  margin: 0 0 4px 0;
}

.tooltipValue {
  font-size: 16px;
  font-weight: 600;
  color: #1e293b;
  margin: 0;
}

.tooltipValue span {
  display: inline-block;
}

.facetLoading,
.facetError,
.noData {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: #64748b;
  flex-direction: column;
  font-size: 14px;
}

.facetLoading {
  background-color: rgba(255, 255, 255, 0.7);
}

.facetError {
  color: #b91c1c;
  background-color: #fef2f2;
}

.noData {
  background-color: #f8fafc;
}

.noFacetsMessage {
  text-align: center;
  padding: 40px;
  background-color: #f8fafc;
  border-radius: 8px;
  color: #64748b;
  font-size: 15px;
  margin-bottom: 20px;
}

.facetCard[data-facet="candidate_created"] .facetHeader {
  border-left: 4px solid #8b5cf6;
}

.facetCard[data-facet="demand_lead_time"] .facetHeader {
  border-left: 4px solid #3b82f6;
}

.facetCard[data-facet="positions_closed"] .facetHeader {
  border-left: 4px solid #14b8a6;
}

.facetCard[data-facet="demand_assigned"] .facetHeader {
  border-left: 4px solid #10b981;
}

.facetCard[data-facet="positions_working"] .facetHeader {
  border-left: 4px solid #f43f5e;
}

@media (max-width: 768px) {
  .analyticsContainer {
    padding: 16px;
  }

  .filterControls {
    flex-direction: column;
    align-items: stretch;
  }

  .formGroup {
    min-width: 100%;
  }

  .fetchButton {
    width: 100%;
    margin-top: 8px;
  }

  .viewOption {
    min-width: 100%;
  }

  .facetsGrid {
    grid-template-columns: 1fr;
  }

  .facetCard {
    height: 300px;
  }
}

:focus-visible {
  outline: 2px solid #3b82f6;
  outline-offset: 2px;
}

.input:focus-visible,
.select:focus-visible,
.datePicker:focus-visible,
.fetchButton:focus-visible {
  outline: 2px solid #3b82f6;
  outline-offset: 2px;
}

@media print {
  .controls {
    display: none;
  }
  
  .facetsGrid {
    display: block;
  }
  
  .facetCard {
    page-break-inside: avoid;
    margin-bottom: 20px;
    box-shadow: none;
    border: 1px solid #e2e8f0;
  }
}
